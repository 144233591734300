import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "grid mr-0" }
const _hoisted_2 = { class: "col-12" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProductionStage = _resolveComponent("ProductionStage")!
  const _component_Card = _resolveComponent("Card")!
  const _component_NotificationCard = _resolveComponent("NotificationCard")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_Card, null, {
          content: _withCtx(() => [
            _createVNode(_component_ProductionStage, {
              isResultView: _ctx.isResultView,
              showSelectionCheckbox: true,
              onLastUpdated: _ctx.isDataUpdated,
              filename: "SOH.WEB",
              "production-stage": _ctx.productionStage,
              "dynamic-columns": _ctx.dynamicColumns,
              "page-title": "Archived Orders",
              "show-copy-button": true,
              "show-split-button": false,
              "show-delete-button": true,
              "dialog-read-only": true,
              "command-name": "CW.MAN.ARCHIVE"
            }, null, 8, ["isResultView", "onLastUpdated", "production-stage", "dynamic-columns"])
          ]),
          _: 1
        })
      ])
    ]),
    _createVNode(_component_NotificationCard)
  ], 64))
}